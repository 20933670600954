<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 12.047 12.047"
  >
    <path
      :style="iconStyle"
      d="M17.273,11.25H12.75V6.727a.75.75,0,0,0-1.5,0V11.25H6.727a.75.75,0,0,0,0,1.5H11.25v4.523a.75.75,0,0,0,1.5,0V12.75h4.523a.75.75,0,0,0,0-1.5Z"
      transform="translate(-5.977 -5.977)"
    />
  </svg>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '12.047',
    },
    width: {
      type: String,
      default: '12.047',
    },
    color: {
      type: String,
      default: 'text-color',
    },
  },
  computed: {
    /**
     * Icon Style
     * @param {void}
     * @returns {string} - Color
     * @description Assigns the specific color to the icon
     */
    iconStyle: function () {
      return {
        fill: ['$' + this.color, `var(--${this.color})`],
      }
    },
  },
}
</script>
